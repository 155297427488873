import './App.css';
import { Route, Link, Routes } from 'react-router-dom';
import Accueil from "./pages/accueil/accueil"
import ReactGA from 'react-ga';

function App() {
  const TRACKING_ID = "G-BYR6QSLQ5T";
ReactGA.initialize(TRACKING_ID);
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname
});
  return (
    
    <div>
      <Routes>
        <Route path="/" element={<Accueil />} />

      </Routes>    </div>
  );
}

export default App;
